import {mapGetters} from "vuex";
import { fieldInfo } from "@/api/base/length";
export default {
  computed:{
    ...mapGetters([
      'userInfo'
    ]),
  },
  data(){
    return {
      tableOption: {
        selectable:(row)=>{
          return  row.actStatus == 'PREPARE' && row.startUserId == this.userInfo.user_id
        },
        linklabel:'actCode',
        column: [{
          label: "项目编号",
          prop: "projectCode",
          align: "center",
          overHidden: true,
          width: 150
        },
          {
            label: "项目名称",
            prop: "projectName",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "编制人员",
            prop: "weaveStaff",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "编制日期",
            prop: "weaveDate",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "天气",
            prop: "weather",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "计划整改",
            prop: "planRectification",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "完成整改",
            prop: "completeRectification",
            align: "left",
            overHidden: true,
            width: 200
          },
          {
            label: "创建时间",
            prop: "createTime",
            align: fieldInfo.datetime.align,
            minWidth: fieldInfo.datetime.length,
            overHidden: true,
          },

        ],
      },
      tableData: [],
      tableLoading: true,
    }
  },
}
